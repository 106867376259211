import React from "react"
import PropTypes from "prop-types"
import Logo from "../images/Logo_Rotschopf.svg"

const Header = ({ siteTitle }) => (
  <header>
    {/*    <span>{siteTitle}</span> */}
    <img src={Logo} />
    {/*    <span>Kreis 3 | Zürich</span> */}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
